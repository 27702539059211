import type { HeadFC } from 'gatsby'
import React from 'react'

import { LanguageHeaders } from '../components/LanguageHeaders'
import Layout from '../components/Layout'
import Impresum_DE from '../content/de/impresum.mdx'
import Impresum_EN from '../content/en/impresum.mdx'
import Impresum_ES from '../content/es/impresum.mdx'
import Impresum_FR from '../content/fr/impresum.mdx'
import Impresum_IT from '../content/it/impresum.mdx'

const ImpresumPage = ({ pageContext }: any) => {
  const language = pageContext.language

  return (
    <Layout light>
      <main className="m-auto w-full lg:max-w-screen-lg grid grid-flow-row p-4 lg:px-0 pt-24 prose">
        {language === 'en' && <Impresum_EN />}
        {language === 'it' && <Impresum_IT />}
        {language === 'fr' && <Impresum_FR />}
        {language === 'es' && <Impresum_ES />}
        {language === 'de' && <Impresum_DE />}
      </main>
    </Layout>
  )
}

export default ImpresumPage

export const Head: HeadFC = ({ pageContext }: any) => {
  const language = pageContext.language
  const translations: any = pageContext.translations
  return (
    <>
      <title>{translations['legal.impresum.title']}</title>
      <meta name="description" content={translations['legal.impresum.description']} />
      <meta name="robots" content="index, follow" />
      <LanguageHeaders pathname={pageContext.defaultPath} locale={language} />
    </>
  )
}
